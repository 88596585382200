<template>
	<div class="adapay-line">
		<el-timeline>
			<el-timeline-item hide-timestamp placement="top">
				<div class="line-dot" slot="dot">1</div>
				<el-card>
					<div class="line-card">
						<div class="left">
							<div class="title">开户进件</div>
							<div class="tip">填写并提交基本信息、商户信息、结算信息等资料申请进件</div>
							<div class="tip1" v-if="adapayData.ApplyState==1">支付商户后台登录账号：{{adapayData.RegisterPhone}}；密码：{{adapayData.LoginPwd}}</div>
							<div class="tip1" v-if="adapayData.ApplyState==8">
								<div style="color:#f56c6c">
									失败原因：{{adapayData.CheckFailReason}}
								</div>
							</div>
						</div>
						<div class="right">
							<div class="state" style="color: #44B449;margin-bottom: 10px;" v-if="adapayData.ApplyState==1">进件成功</div>
							<div class="state" style="margin-bottom: 10px;" v-else-if="adapayData.ApplyState==7">进件中</div>
							<div class="state" style="color: #f56c6c;margin-bottom: 10px;" v-else-if="adapayData.ApplyState==8">进件失败</div>
							<el-button type="primary" v-if="adapayData.ApplyState==8" @click="handleJump('submitInformation',{again:true})">重新进件</el-button>
							<el-link type="primary" @click="handleJump('submitInformationDetail')" v-else>查看资料</el-link>
						</div>
					</div>
				</el-card>
			</el-timeline-item>
			<el-timeline-item hide-timestamp placement="top">
				<div class="line-dot" slot="dot">2</div>
				<el-card>
					<div class="line-card">
						<div class="left">
							<div class="title">补充证件照</div>
							<div class="tip" v-if="adapayData.ApplyState !== 3">
								提交法人身份证正反面照、开户许可证、门店照等信息申请入驻。
								<span style="color: #F56C6C;">请在五个工作日内提交并通过审核</span>，
								超时将关闭申请，后续需要更换手机号重新进件
							</div>
							<div class="tip" v-else>
								未在进件成功后5个工作日内通过证件照材料审核，商户申请关闭，您需要更换手机号重新进件
							</div>
							<div class="tip1" style="color: #F56C6C;" v-if="adapayData.ApplyState===4 && adapayData.CheckFailReason">失败原因：{{adapayData.CheckFailReason}}</div>
						</div>
						<div class="right" v-if="adapayData.ApplyState>=1&&adapayData.ApplyState!=7&&adapayData.ApplyState!=8">
							<div class="state" style="color: #409EFF;margin-bottom: 10px;" v-if="adapayData.ApplyState===1">待补充</div>
							<div class="state" style="color: #409EFF;margin-bottom: 10px;" v-if="adapayData.ApplyState===2">审核中</div>
							<div class="state" style="color: #F56C6C;margin-bottom: 10px;" v-if="adapayData.ApplyState===3">入驻关闭</div>
							<div class="state" style="color: #F56C6C;margin-bottom: 10px;" v-if="adapayData.ApplyState===4">入驻失败</div>
							<div class="state" style="color: #44B449;margin-bottom: 10px;" v-if="adapayData.ApplyState===5">入驻成功</div>
							
							<el-button type="primary" @click="handleJump('replenishInformation')" v-if="adapayData.ApplyState===1">立即补充</el-button>
							<el-link type="primary" @click="handleJump('replenishInformation', {isLook: 1})" v-if="adapayData.ApplyState===2">查看资料</el-link>
							<el-button type="primary" @click="handleJump('submitInformation',{again:true})" v-if="adapayData.ApplyState===3">重新进件</el-button>
							<el-button type="primary" @click="handleJump('replenishInformation')" v-if="adapayData.ApplyState===4">重新提交</el-button>
							<el-link type="primary" @click="handleJump('replenishInformation', {isLook: 1})" v-if="adapayData.ApplyState===5">查看资料</el-link>
						</div>
					</div>
				</el-card>
			</el-timeline-item>
			<el-timeline-item hide-timestamp placement="top">
				<div class="line-dot" slot="dot">3</div>
				<el-card>
					<div class="line-card">
						<div class="left">
							<div class="title">开通支付渠道</div>
							<div class="tip">填写经营类目等信息，完成配置并开通微信端支付渠道</div>
						</div>
						<div class="right">
							<div class="state" style="color: #409EFF;margin-bottom: 10px;" v-if="adapayData.ApplyState===5">待开通</div>
							<div class="state" style="color: #44B449;margin-bottom: 10px;" v-if="adapayData.ApplyState===6">开通成功</div>
							<el-button type="primary" @click="handleJump('openPayby')" v-if="adapayData.ApplyState===5">前往开通</el-button>
							<el-link type="primary" @click="handleJump('replenishInformation', {isLook: 1})" v-if="adapayData.ApplyState===6">查看资料</el-link>
						</div>
					</div>
				</el-card>
			</el-timeline-item>
		</el-timeline>
	</div>
</template>

<script>
	import config from '@/config/index';


	export default {
		components: {},
		props: {
			adapayData: {
				type: Object,
				default: ()=>{
					return {}
				}
			}
		},
		data() {
			return {

			}
		},
		created() {
		},
		methods: {
			handleJump(name, query){
				this.$router.push({name: name, query: query||{}});
			}
		}
	}
</script>

<style lang="less" scoped>
	.adapay-line {
		margin-right: 20px;
		
		.line-dot{
			width: 20px;
			height: 20px;
			line-height: 20px;
			border: 1px solid #ddd;
			border-radius: 100%;
			background-color: #fff;
			margin-left: -5px;
			font-size: 12px;
			color: #999;
			text-align: center;
		}
		
		.line-card{
			display: flex;
			
			.left{
				flex: 1 1 auto;
				overflow: hidden;
				
				.title{
					font-size: 16px;
					font-weight: bold;
				}
				
				.tip{
					margin-top: 10px;
					font-size: 12px;
					color: #999;
				}
				
				.tip1{
					margin-top: 10px;
					font-size: 14px;
					color: #333;
				}
			}
			.right{
				flex: 0 0 auto;
				overflow: hidden;
				width: 180px;
				
				text-align: right;
			}
			.state{
				font-size: 15px;
			}
		}
	}
</style>
