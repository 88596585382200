<template>
  <div style="margin-left: 10px;line-height: 18px;">
    <div class="pay-info" style="width: 520px;" v-if="configData.Id">
			<div class="icon">
				<i class="el-icon-success icon-font"></i>
			</div>
			<div class="info">
				<div class="title" style="font-size: 16px;">已绑定联厚支付商户号，绑定信息如下</div>
				<div class="config-info">
					<div class="info-item">
						<div class="tit">商户号: </div>
						<div class="cont">{{configData.MercCode}}</div>
					</div>
					<el-button type="text" style="margin:0px;padding:0px" @click="tobind">修改配置</el-button>
				</div>
				<div style="margin-top:15px;">
						<a style="font-size:13px;color:#409eff"  target="_blank" underline = "false" href = "https://smf.jingweitech.cn/login?from=/home" type="primary">点击登录联厚支付商户后台</a>
				</div>
			</div>
		</div>
    
    <el-button type="primary" class="margin-left-10" @click="tobind" v-else>绑定商户号</el-button>

    <el-dialog :visible.sync="changeShow" width="750px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" center>
      <div slot="title" class="dialog-title">
				绑定商户号
			</div>
      <div class="flex flex-align-center">
        <div class="keyLabel start">联厚支付商户号：</div>
        <el-input v-model="changeData.MercCode" style="width:300px" placeholder="请输入联厚支付商户号" maxlength="50"></el-input>
      </div>
      <div slot="footer" class="flex flex-justify-center">
        <el-button @click="handleClose">关闭</el-button>
        <el-button type="primary" :loading="loading" @click="handleSubmit">确认绑定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mallpayconfigsave
} from '@/api/TurnTomySelf'
export default {
  props: {
    configData: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  data () {
    return {
      changeShow:false,
      loading:false,
      changeData:{}
    }
  },
  methods:{
    tobind(){
      this.changeData = JSON.parse(JSON.stringify(this.configData))
      this.changeShow = true
    },
    handleClose(){
      this.changeShow = false
    },
    async handleSubmit(){
      if(!this.changeData.MercCode){
        this.$message.error('请输入联厚支付商户号')
        return 
      }
      try{
        this.loading = true
        let res = await mallpayconfigsave({
          // PayBy:10,
          SmfPayConfig:this.changeData
        })
        if(res.IsSuccess){
          this.changeShow = false;
					this.$emit('reload')
        }
      }finally{
        this.loading = false
      }
    }
  }
}
</script>

<style lang='less' scoped>
  
.pay-info {
  display: flex;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  max-width: 800px;
}
.icon {
  flex: 0 0 auto;
  margin-right: 10px;

  .icon-font {
    font-size: 60px;
    color: #44b449;
  }
}

.info {
  flex: 1 1 auto;
  overflow: hidden;

  .title {
    font-size: 20px;
    padding-top: 10px;
  }

  .tips {
    font-size: 14px;
    color: #999;
    margin-top: 10px;
  }

  .bind-info {
    font-size: 12px;
    color: #999;
    margin-bottom: 5px;
  }
  
  .config-info{
    position: relative;
    padding: 5px 15px 5px 15px;
    margin-top: 15px;
    width: 400px;
    font-size: 13px;
    background-color: #f5f7fa;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info-item{
      display: flex;
      margin: 10px 0px;
      color: #000;
      flex: 1;
      width: 0;
      
      .tit{
        flex: 0 0 auto;
        text-align: right;
        margin-right: 5px;
      }
      .cont{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  
  .edit-config{
    position: absolute;
    right: 20px;
    bottom: 15px;
  }
}
.dialog-title {
  font-size: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}


		
</style>