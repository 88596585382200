<template>
	<div class="wx-pay">
		
		<!-- 未设置 -->
		<div class="pay-info" v-if="!ruleForm.Id">
			<div class="icon">
				<i class="el-icon-success icon-font"></i>
			</div>
			<div class="info">
				<div class="title">我已有微信支付商户号</div>
				<div class="tips">
					若您已申请了微信支付商户号，并且支付商户号主体和商城小程序主体一致，无需再次申请，点击下方[绑定商户号]进行绑定即可。
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnQmJYBp9S8iO4JwRC33RoSg" target="_blank">如何验证主体是否一致？</el-link>
				</div>
				<el-button type="primary" style="margin-top: 22px;" @click="visible=true">绑定商户号</el-button>

				<div class="bind-info" style="margin-top: 22px;">绑定流程：</div>
				<div class="bind-info">
					● 小程序内关联微信支付商户号，
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccneDoRWMrM2n6PRLAZHkVMEh" target="_blank">如何关联？</el-link>
				</div>
				<div class="bind-info">
					● 下载退款证书，
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccno1pXeCzZ80hP2GWGvYkKzS" target="_blank">如何下载？</el-link>
				</div>
				<div class="bind-info">
					● 设置支付密钥，
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccndpHlfVE6HOHqQwguuWZqqc" target="_blank">如何设置？</el-link>
				</div>
				<div class="bind-info">● 填写商户号、密钥、上传退款证书绑定，<el-link type="primary" @click="visible=true">点击绑定</el-link>
				</div>
			</div>
		</div>

		<div class="pay-info" style="margin-top: 20px;" v-if="!ruleForm.Id">
			<div class="icon">
				<i class="el-icon-error icon-font" style="color: #999;"></i>
			</div>
			<div class="info">
				<div class="title">我还没有微信支付商户号</div>
				<div class="tips">
					若您还没有申请过微信支付商户号，或已申请的商户号主体与小程序主体不一致，点击下方[立即申请]前往申请。
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnQmJYBp9S8iO4JwRC33RoSg" target="_blank">如何验证主体是否一致？</el-link>
				</div>

				<el-link type="primary" href="https://pay.weixin.qq.com/index.php/apply/applyment_home/guide_normal"
					target="_blank">
					<el-button type="primary" style="margin-top: 22px;">立即申请</el-button>
				</el-link>

				<div class="bind-info" style="margin-top: 22px;">申请流程：</div>
				<div class="bind-info">● 提交申请资料</div>
				<div class="bind-info">● 等待微信支付官方审核</div>
				<div class="bind-info">● 查收开户邮件</div>
				<div class="bind-info">● 验证打款金额</div>
				<div class="bind-info">● 登录商户平台签署协议</div>
				<div class="bind-info">● 绑定申请成功的商户号</div>
			</div>
		</div>
		
		<!-- 已设置 -->
		<div class="pay-info" v-if="ruleForm.Id" style="width: 520px;">
			<div class="icon">
				<i class="el-icon-success icon-font"></i>
			</div>
			<div class="info">
				<div class="title" style="font-size: 16px;">已绑定支付商户号，绑定信息如下</div>
				<div class="config-info">
					<div class="info-item">
						<div class="tit">微信支付商户号: </div>
						<div class="cont">{{ruleForm.WxPayMchId}}</div>
					</div>
					<div class="info-item">
						<div class="tit">支付密钥: </div>
						<div class="cont">{{ruleForm.PrivateKey}}</div>
						<div class="icon"></div>
					</div>
					<div class="info-item"><div class="tit">微信退款证书: </div>已上传</div>
					<el-link type="primary" class="edit-config" @click="visible=true">修改配置</el-link>
				</div>
				<div style="margin-top:15px;">
						<a style="font-size:13px;color:#409eff"  target="_blank" underline = "false" href = "http://pay.weixin.qq.com" type="primary">点击登录微信支付商户后台</a>
				</div>
			</div>
		</div>


		<el-dialog :visible.sync="visible" width="750px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" center>

			<div slot="title" class="dialog-title">
				绑定商户号
			</div>

			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px">
				<el-form-item label="微信支付商户号:" prop="WxPayMchId">
					<el-input v-model="ruleForm.WxPayMchId" style="width: 300px;" placeholder="请输入与小程序绑定的微信支付商户号"></el-input>
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnQJFJZ4cSM5esX3O9Wmyizf" target="_blank"
						style="margin-left: 10px;height: 20px;">如何获取商户号？</el-link>
				</el-form-item>
				<el-form-item label="支付密钥:" prop="PrivateKey">
					<el-input type="textarea" :rows="1" v-model="ruleForm.PrivateKey" style="width: 300px;" maxlength="32" placeholder="请输入微信支付API密钥"></el-input>
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccndpHlfVE6HOHqQwguuWZqqc" target="_blank"
						style="margin-left: 10px;height: 20px;">如何获取密钥？</el-link>
				</el-form-item>
				<el-form-item label="微信退款证书:" prop="WxRefundCertImgUrl">
					<el-upload class="upload-demo" :action="upLoadFile" :show-file-list="false"
						:on-success="handleAvatarSuccessBook" style="display: inline-block;marin-left:10px;">
						<!-- 如果没有退款证书 -->
						<el-button type="primary" plain v-if="!ruleForm.WxRefundCertImgUrl">上传退款证书</el-button>
						<!-- 如果有退款证书 -->
						<el-button type="primary" plain v-else>更换退款证书</el-button>
					</el-upload>

					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccno1pXeCzZ80hP2GWGvYkKzS" target="_blank"
						style="margin-left: 10px;height: 20px;">如何获取退款证书？</el-link>
				</el-form-item>
			</el-form>


			<span slot="footer">
				<div class="dialog-footer">
					<el-button @click="handleClose">关闭</el-button>
					<el-button type="primary" :loading="loading" @click="handleSubmit">确认绑定</el-button>
				</div>
				<div style="text-align: right;margin-top: 30px;">
					<el-link type="primary" href="https://pay.weixin.qq.com/index.php/apply/applyment_home/guide_normal"
						target="_blank">我还没有微信支付商户号，现在去申请>>>
					</el-link>
				</div>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import config from '@/config/index';
	
	import {
		mallpayconfigsave
	} from '@/api/TurnTomySelf'

	export default {
		props: {
			configData: {
				type: Object,
				default: {}
			}
		},
		data() {

			var checkPrivateKey = (rule, value, callback) => {
				if (!this.ruleForm.PrivateKey){
					return callback(new Error('请输入支付密钥'));
				}else if (this.ruleForm.PrivateKey.length != 32) {
					return callback(new Error('API密钥为32位字符，支持数字/大小写字母'));
				} else {
					return callback();
				}
			};

			return {
				visible: false,
				upLoadFile: config.UPLOAD_ZIP,
				
				loading: false,

				ruleForm: {
					Id: 0,
					WxPayMchId: '',
					WxRefundCertImgUrl: '',
					PrivateKey: ''
				},
				rules: {
					WxPayMchId: [{
						required: true,
						message: '请输入微信支付商户号',
						trigger: 'change',
					}],
					PrivateKey: [{
						required: true,
						trigger: 'change',
						validator: checkPrivateKey
					}],
					WxRefundCertImgUrl: [{
						required: true,
						trigger: 'change',
						message: '请上传微信退款证书',
					}]
				}
			}
		},
		watch: {
			configData(){
				this.initData();
			}
		},
		created() {
			this.initData();
		},
		methods: {
			initData(){
				for (var item in this.configData){
					this.ruleForm[item] = this.configData[item]
				}
			},
			handleAvatarSuccessBook(res, file) {
				this.ruleForm.WxRefundCertImgUrl = res[0];
				this.$refs['ruleForm'].validateField('WxRefundCertImgUrl');
				this.$forceUpdate();
			},
			handleClose(){
				this.ruleForm = JSON.parse(JSON.stringify(this.configData));
				
				//重置数据和clearValidate 会出现顺序错乱，所以等重置数据执行完再执行clearValidate
				this.$nextTick(()=>{
					this.$refs['ruleForm'].clearValidate();
					this.visible = false;
				})
				
			},
			handleSubmit() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.saveData();
					}
				});
			},
			async saveData(){
				
				this.loading = true;
				
				try{
					const res = await mallpayconfigsave({
						WxPayConfig: this.ruleForm
					})
					if (res.IsSuccess){
						this.visible = false;
						this.$emit('reload')
					}
				}catch(err){
					
				}finally{
					this.loading = false;
				}
				
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.wx-pay {
		margin-left: 10px;
		line-height: 18px;

		.pay-info {
			display: flex;
			padding: 10px 15px;
			border: 1px solid #ddd;
			border-radius: 6px;
			max-width: 800px;
		}

		.icon {
			flex: 0 0 auto;
			margin-right: 10px;

			.icon-font {
				font-size: 60px;
				color: #44b449;
			}
		}

		.info {
			flex: 1 1 auto;
			overflow: hidden;

			.title {
				font-size: 20px;
				padding-top: 10px;
			}

			.tips {
				font-size: 14px;
				color: #999;
				margin-top: 10px;
			}

			.bind-info {
				font-size: 12px;
				color: #999;
				margin-bottom: 5px;
			}
			
			.config-info{
				position: relative;
				padding: 15px 15px 5px 15px;
				margin-top: 15px;
				width: 400px;
				font-size: 13px;
				background-color: #f5f7fa;
				border: 1px solid #ddd;
				
				.info-item{
					display: flex;
					margin-bottom: 10px;
					color: #000;
					
					.tit{
						flex: 0 0 auto;
						width: 100px;
						text-align: right;
						margin-right: 5px;
					}
					.cont{
						flex: 1 1 auto;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
			
			.edit-config{
				position: absolute;
				right: 20px;
				bottom: 15px;
			}
		}
	}

	.dialog-title {
		font-size: 15px;
		text-align: left;
		border-bottom: 1px solid #ddd;
		padding-bottom: 10px;
	}
</style>
