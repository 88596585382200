<template>
  <div>
    <div class="line-box">
      <div class="content">
        <img v-if="type=='wx'" src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/paychannel-wx.png" alt="">
        <img v-else src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/paychannel-zfb.png" alt="">
        <div>
          <div class="content-right">
            <div class="ri-le">
              <div class="title">{{type=='wx'?'微信支付':'支付宝支付'}}</div>
            </div>
            <div class="ri-ri" v-if="type=='wx'">
              <span v-if="state==5">待入驻</span>
              <span v-if="state==9">入驻审核中</span>
              <span style="color:#f56c6c" v-if="state==10">入驻失败</span>
              <span style="color:#44B449" v-if="state==6||hasConfig">入驻成功</span>
            </div>
          </div>
          <!-- 未进件=0， 进件成功=1，证照审核中=2，商户关闭=3，审核失败=4，审核通过=5，入驻成功=6，进件中 = 7，进件失败 = 8,入驻审核中 = 9,入驻失败 = 10 -->
          <div class="content-right">
            <div class="ri-le">
              <div class="text">
                {{type=='wx'?'填写经营类目等信息，完成配置并入驻微信支付渠道，入驻成功后，客户在小程序内购买商品可使用微信支付'
                :'入驻支付宝支付渠道，入驻成功后，客户在小程序内购买商品可使用支付宝支付'}}
              </div>
            </div>
            <div v-if="type=='wx'">
              <el-button type="primary" v-if="state==5" @click="$router.push({path:'/setting/openPayby'})">前往入驻</el-button>
              <el-button type="text" v-if="state==9" @click="viewShow=true">查看资料</el-button>
              <el-popover placement="bottom" trigger="hover" v-show="state==10">
                <div class="flexRow flexCol">
                  <div style="font-size:14px;color:#333333FF">
                    添加专属客服
                  </div>
                  <div style="font-size:14px;color:#999999FF;margin-top:5px">
                    一对一为您解答疑惑
                  </div>
                  <div style="width:100%;text-align:center">
                    <img src="@/assets/img/20210719171848.jpg" style="width:150px;height:150px;margin-top:10px" alt="">
                  </div>
                </div>
                <el-button type="text" slot="reference">联系客服</el-button>
              </el-popover>
              <div class="flexRow" v-if="state==6||hasConfig">
                <el-button type="text" v-if="!config.IsOpenWxPay" @click="changechannel(true,0)">开启渠道</el-button>
                <el-button type="text" v-else style="color:#f56c6c" @click="changechannel(false,0)">关闭渠道</el-button>
                <el-popover
                  placement="bottom"
                  width="200"
                  trigger="hover"
                  content="开启渠道后，客户在小程序内下单可选择使用微信支付">
                  <i class="el-icon-question" style="font-size:14px;margin-left:5px;color:#CCCCCCFF" slot="reference"></i>
                </el-popover>
              </div>
            </div>
            <div v-else>
              <div class="flexRow">
                <el-button type="text" v-if="!config.IsOpenAlipay" @click="changechannel(true,1)">开启渠道</el-button>
                <el-button type="text" v-else style="color:#f56c6c" @click="changechannel(false,1)">关闭渠道</el-button>
                <el-popover
                  placement="bottom"
                  width="200"
                  trigger="hover"
                  content="开启渠道后，客户在小程序内下单可选择使用支付宝支付">
                  <i class="el-icon-question" style="font-size:14px;margin-left:5px;color:#CCCCCCFF" slot="reference"></i>
                </el-popover>
              </div>
            </div>
          </div>
          <div class="content-right" v-if="state==10">
            <div class="ri-le">
              <div class="text ri-error">失败原因： 接口返回的失败原因接口返回的失败原因接口返回的失败原因接口返回的失败原因接口返回的失败原因</div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="viewShow" title="微信入驻资料" width="350px" custom-class="dialog-body-paddingTop-10">
      <div class="flexRow" style="margin-bottom:20px">
        <div class="letext">企业主体类型：</div>
        <div class="ritext">{{addressmsg.MerchantType}}</div>
      </div>
      <div class="flexRow" style="margin-bottom:20px">
        <div class="letext">经营所在地省份：</div>
        <div class="ritext">{{addressmsg.BusinessAddressProvince}}</div>
      </div>
      <div class="flexRow" style="margin-bottom:20px">
        <div class="letext">经营所在地城市：</div>
        <div class="ritext">{{addressmsg.BusinessAddressCity}}</div>
      </div>
      <div class="flexRow" style="margin-bottom:20px">
        <div class="letext">经营所在地县区：</div>
        <div class="ritext">{{addressmsg.BusinessAddressDistrict}}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {

} from '@/api/TurnTomySelf';
export default {
  props: {
    type: {
      type: String,
      default: 'wx'
    },
    hasConfig: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    addressmsg: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    state:{
      type:Number,
      default:0
    }
  },
  data () {
    return {
      viewShow:false,
    }
  },
  watch:{

  },
  created () {

  },
  methods: {
    changechannel(isopen,type){
      this.$emit('changechannel',{
        MallAdaPayConfigId:this.config.Id,
        PayChannel:type,
        IsOpen:isopen
      })
    }
  }
}
</script>

<style lang="less" scoped>
.line-box{
  padding:10px 15px;
  width: 520px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  font-size: 12px;
  color: #999;
  display: inline-block;
  margin-left: 10px;
  .content{
    display: flex;
    align-items: flex-start;
    img{
      width: 36px;
    }
    .content-right{
      flex:1;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .ri-le{
        margin-left: 10px;
        margin-right: 36px;
        .title{
          font-size: 18px;
          color: #000000;
          line-height: 20px;
          margin-top: 6px;
        }
        .text{
          font-size: 12px;
          color: #909399;
          line-height: 18px;
          margin-top: 6px;
        }
      }
      .ri-ri{
        line-height: 1.5;
        font-size: 14px;
        color: #409EEF;
      }
    }
  }
}
.ri-success{
  color: #44B449FF !important;
}
.ri-error{
  color: #F56C6CFF !important;
}
.flexRow{
  display: flex;
  align-items: center;
}
.flexCol{
  flex-direction: column;
}
.letext{
  text-align:right;width:120px;
  color: #606266;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
.ritext{
  color: #606266;
  font-size: 14px;
  line-height: 20px;
}
</style>