<template>
  <div>
    <el-timeline class="times-line">
      <el-timeline-item 
        icon="el-icon-circle-check"
        v-for="(item,index) in payApplyList" 
        :key="item.AddTime+ index"  
        :color="index==0 ? '#409EFF': '#909399'"
        :timestamp="item.AddTime"
        placement="top">

        <div  class="times-card" v-show="item.RecordType== 5">
          <p class="remark-infos">{{item.Remark}} 
            <el-button type="text"  @click="scanCode(item.WxSigningQrCode)" v-show="applyState==5">扫码签约</el-button>
          </p>
        </div>

        <div class="times-card" v-show="item.RecordType== 4">
          <p  class="remark-infos">
            {{item.Remark}}
            <el-button type="text" @click="openNewWindow(1)" v-show="applyState==4">去登录支付后台</el-button>
          </p>
          <p  class="remark-infos">第三方支付商户号：
            {{item.Mall498Number}}
          </p>
          <p  class="remark-infos">默认登录密码：
            {{item.Mall498Password}}
          </p>
        </div>

        <div  class="times-card" v-show="item.RecordType== 3">
          <p class="remark-infos">{{item.Remark}}</p>
        </div>

        <div  class="times-card" v-show="item.RecordType== 2">
          <p  class="remark-infos">第三方支付申请资料失败，请修改资料后，重新提交。
            <el-button type="text" @click='openNewWindow(2)' v-show="applyState==2">去修改</el-button>
          </p>
          <p class="error-infos">
            失败原因：
            {{item.Reason}}
          </p>
        </div>

        <div  class="times-card" v-show="item.RecordType== 1">
          <p class="remark-infos">
            {{item.Remark}} 
            <el-button type="text" @click="scanCode(item.WxSigningQrCode)" v-show="applyState==1">扫码签约</el-button>
          </p>
        </div>

        <div  class="times-card" v-show="item.RecordType== 0">
          <p class="remark-infos">
            {{item.Remark}} 
            <el-button type="text"  @click="openNewWindow(3)">查看资料</el-button>
          </p>
        </div>

      </el-timeline-item>
    </el-timeline>

    <el-dialog title="扫码签约" :visible.sync="codeVisible" width="500px"  @closed="closedCode">
      <img :src="imgUrl+WxSigningQrCode" alt="" id="codeId" style="width:300px;display: block;margin: 0 auto;margin-bottom: 20px;">
      <div class="remark">
        注：请使用法人微信扫码签约，扫码签约后，点击已签约。
        <el-button type="text" @click="openNewWindow(4)">如何签约？</el-button>
      </div>
      <div style="text-align:center;margin-top: 25px;">
        <el-button type="primary" style="width:200px" @click="hasSigned">已签约</el-button>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import { freePayApplySignFinish } from '@/api/goods'
import config from '@/config/index'
export default {
  props: {
    payApplyList: {
      type: Array,
      default() {
        return []
      }
    },
    applyState: {
      type: Number,
      default() {
        return 0
      }
    },
  },
  data () {
    return {
      imgUrl: config.IMG_BASE,
      codeVisible: false,
      WxSigningQrCode: null,
    }
  },
  created () {
    
  },
  methods: {
    closedCode() {
      this.codeVisible = false
    },
    scanCode(code) {
      this.codeVisible = true
      this.WxSigningQrCode = code
    },
    openNewWindow(index){
      switch (index){
        case 1:
          window.open('http://8.498.net/member.php/Index/index.html')
          break;
        case 2:
          window.localStorage.setItem('canEditSetinfos', true)
          this.$router.push({
            path: '/setting/setPayApply'
          })
          break;
        case 3:
          window.localStorage.setItem('canEditSetinfos', false)
          this.$router.push({
            path: '/setting/setPayApply'
          })
          break;
        case 4:
          window.open('https://www.yuque.com/docs/share/8ceb022c-c6cf-4308-99cd-2a9e846af44c?#')
          break;
      }
    },
    async hasSigned() {      
      let result = await freePayApplySignFinish()
      if(result.IsSuccess) {
        this.codeVisible = false
        this.$emit('hasSigned')
      }
    },
  }
}
</script>

<style lang='less' scoped>

.times-line {
  margin-left: 70px;
  display: flex;
  flex-direction: column;
  .times-card {
    .remark-infos {
      color: #909399;
    }
    .error-infos {
      color: #F56C6C;
    }
  }

}
#btnsavaImg {
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
		display: inline-block;
		line-height: 1;
		white-space: nowrap;
		border: 0;
		cursor: pointer;
		-webkit-appearance: none;
		text-align: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		outline: 0;
		margin: 0;
		-webkit-transition: .1s;
		transition: .1s;
		font-weight: 500;
		padding: 9px 15px;
		font-size: 14px;
		border-radius: 4px;
		margin: 20px 0;
	}

</style>