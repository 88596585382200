<template>
	<div class="wx-pay">
		
		<div class="bind-line" v-if="payApplyList.length && !ruleForm.Id">
			
			<div class="i-have">我已申请云收单支付商户号，<el-link type="primary" @click="visible=true">点击绑定</el-link></div>
			
			<div class="line">
				<div class="line-title">申请云收单支付</div>
				<ApplyPayLine :payApplyList='payApplyList' :applyState='applyState' @hasSigned='hasSigned'></ApplyPayLine>
			</div>
			
		</div>
		
		
		<!-- 未设置 -->
		<div v-else>
			<div class="pay-info" v-if="!ruleForm.Id">
				<div class="icon">
					<i class="el-icon-success icon-font"></i>
				</div>
				<div class="info">
					<div class="title">我已有云收单支付商户号</div>
					<div class="tips">
						若您已申请了云收单支付商户号，并且支付商户号主体和商城小程序主体一致，无需再次申请，点击下方[绑定商户号]进行绑定即可。
						<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnQmJYBp9S8iO4JwRC33RoSg" target="_blank">如何验证主体是否一致？</el-link>
					</div>
					<el-button type="primary" style="margin-top: 22px;" @click="visible=true">绑定商户号</el-button>

					<div class="bind-info" style="margin-top: 22px;">绑定流程：</div>
					<div class="bind-info">● 获取支付商户号，<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccn4E6KIummPN4YcDj5BnMg2c"
							target="_blank">如何获取？</el-link>
					</div>
					<div class="bind-info">● 获取支付密钥，<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccn4E6KIummPN4YcDj5BnMg2c" target="_blank">
							如何获取？</el-link>
					</div>
					<div class="bind-info">● 填写商户号、密钥绑定，<el-link type="primary" @click="visible=true">点击绑定</el-link>
					</div>
				</div>
			</div>

			<div class="pay-info" style="margin-top: 20px;" v-if="!ruleForm.Id">
				<div class="icon">
					<i class="el-icon-error icon-font" style="color: #999;"></i>
				</div>
				<div class="info">
					<div class="title">我还没有云收单支付商户号</div>
					<div class="tips">
						若您还没有申请过云收单支付商户号，或已申请的商户号主体与小程序主体不一致，点击下方[立即申请]前往申请。
						<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnQmJYBp9S8iO4JwRC33RoSg" target="_blank">如何验证主体是否一致？</el-link>
					</div>


					<el-button type="primary" style="margin-top: 22px;" :loading="signLoding" @click="handleSign">立即申请
					</el-button>


					<div class="bind-info" style="margin-top: 22px;">申请流程：</div>
					<div class="bind-info">● 提交申请资料</div>
					<div class="bind-info">● 等待云收单支付官方审核</div>
					<div class="bind-info">● 扫码签约</div>
					<div class="bind-info">● 绑定申请成功的商户号</div>
				</div>
			</div>

			<!-- 已设置 -->
			<div class="pay-info" v-if="ruleForm.Id" style="width: 520px;">
				<div class="icon">
					<i class="el-icon-success icon-font"></i>
				</div>
				<div class="info">
					<div class="title" style="font-size: 16px;">已绑定云收单支付商户号，绑定信息如下</div>
					<div class="config-info">
						<div class="info-item">
							<div class="tit">支付商户号: </div>
							<div class="cont">{{ruleForm.PayMchId}}</div>
						</div>
						<div class="info-item">
							<div class="tit">支付密钥: </div>
							<div class="cont">{{ruleForm.PrivateKey}}</div>
							<div class="info-icon" @click="show=true">
								<!-- <i class="el-icon-edit-outline"></i> -->
								<img style="width: 20px;transform: rotate(90deg);" src="http://cdn.dkycn.cn/images/melyshop/dky-scale-icon.png?v=4">
							</div>
						</div>
						<div class="info-item"> </div>
						<el-link type="primary" class="edit-config" @click="visible=true">修改配置</el-link>
					</div>
					<div style="margin-top:15px;">
						<a style="font-size:13px;color:#409eff"  target="_blank" underline = "false" href = "http://8.498.net/member.php/Index/index.html" type="primary">点击登录云收单支付商户后台</a>
				</div>
				</div>
			</div>
		</div>
		
		

		<el-dialog :visible.sync="visible" width="750px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" center>

			<div slot="title" class="dialog-title">
				绑定商户号
			</div>

			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
				<el-form-item label="云收单支付商户号:" prop="PayMchId">
					<el-input v-model="ruleForm.PayMchId" style="width: 300px;" placeholder="请输入云收单支付商户号"></el-input>
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccn4E6KIummPN4YcDj5BnMg2c" target="_blank"
						style="margin-left: 10px;height: 20px;">如何获取商户号？</el-link>
				</el-form-item>
				<el-form-item label="支付密钥:" prop="PrivateKey">
					<el-input type="textarea" :rows="1" v-model="ruleForm.PrivateKey" style="width: 300px;" placeholder="请输入云收单支付密钥"
						></el-input>
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccn4E6KIummPN4YcDj5BnMg2c" target="_blank"
						style="margin-left: 10px;height: 20px;">如何获取密钥？</el-link>
				</el-form-item>

			</el-form>


			<span slot="footer">
				<div class="dialog-footer">
					<el-button @click="handleClose">关闭</el-button>
					<el-button type="primary" :loading="loading" @click="handleSubmit">确认绑定</el-button>
				</div>
				<div style="text-align: right;margin-top: 30px;">
					<el-link type="primary" @click="handleSign">我还没有云收单支付商户号，现在去申请>>></el-link>
				</div>
			</span>
		</el-dialog>


		<el-dialog :visible.sync="show" width="400px" center>
			<div slot="title" class="dialog-title">
				支付密钥
			</div>
			<div>{{ruleForm.PrivateKey}}</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="show=false">关闭</el-button>
			</span>
		</el-dialog>

		<el-dialog :visible.sync="setVisible" width="500px" center>
			<div slot="title" class="dialog-title">提示</div>
			<div>
				申请支付入驻，需要提供营业执照等相关信息，请确保商城小程序主体与营业执照上的主体一致。
				<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnQmJYBp9S8iO4JwRC33RoSg" target="_blank">如何验证主体是否一致？</el-link>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button  @click="setVisible=false">关闭</el-button>
				<el-button type="primary" @click="toSign">继续申请</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import config from '@/config/index';

	import {
		mallpayconfigsave
	} from '@/api/TurnTomySelf';
	import {
		freePayApplyInfo
	} from '@/api/goods.js';
	
	import ApplyPayLine from '@/components/ApplyPayLine.vue'

	export default {
		components:{
			ApplyPayLine
		},
		props: {
			configData: {
				type: Object,
				default: {}
			},
			mallConfigInfo: {
				type: Object,
				default: {}
			}
		},
		data() {


			return {
				upLoadFile: config.UPLOAD_ZIP,

				visible: false,
				show: false,
				setVisible: false,

				loading: false,
				signLoding: false,
				
				applyState: 0,
				payApplyList: [],

				ruleForm: {
					Id: 0,
					PayMchId: '',
					PrivateKey: ''
				},
				rules: {
					PayMchId: [{
						required: true,
						message: '请输入支付商户号',
						trigger: 'change',
					}],
					PrivateKey: [{
						required: true,
						message: '请输入支付密钥',
						trigger: 'change',
					}],
				}
			}
		},
		watch: {
			configData() {
				console.log('configData', this.configData)
				this.initData();
			}
		},
		created() {
			this.initData();
			this.getPayApplyData();
		},
		methods: {
			initData() {
				for (var item in this.configData){
					this.ruleForm[item] = this.configData[item]
				}
			},
			handleAvatarSuccessBook(res, file) {
				this.ruleForm.WxRefundCertImgUrl = res[0];
				this.$refs['ruleForm'].validateField('WxRefundCertImgUrl');
			},
			handleClose(){
				this.ruleForm = JSON.parse(JSON.stringify(this.configData));
				
				//重置数据和clearValidate 会出现顺序错乱，所以等重置数据执行完再执行clearValidate
				this.$nextTick(()=>{
					this.$refs['ruleForm'].clearValidate();
					this.visible = false;
				})
			},
			handleSubmit() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.saveData();
					}
				});
			},
			async saveData() {

				this.loading = true;
				try{
					const res = await mallpayconfigsave({
						FourNineEigthPayConfig: this.ruleForm
					})
					
					if (res.IsSuccess) {
						this.visible = false;
						this.$emit('reload')
					}
				}catch(err){
					
				}finally{
					this.loading = false;
				}
				

			},

			async getPayApplyData() {
				try {
					this.loading = true
					let result = await freePayApplyInfo()
					this.applyState = result.Result.ApplyState || null
					this.payApplyList = result.Result.RecordList || []
				} catch (e) {} finally {
					this.loading = false
				}
			},
			hasSigned() {
				this.getPayApplyData()
			},


			handleSign() {
				// 点击申请，再次验证免费商户是否设置了小程序
				this.signLoding = true;
				this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
					this.signLoding = false;
					this.state = window.localStorage.getItem('mlmzFreePayState')
					if (this.state == 0) {
						this.$confirm('请先绑定商城小程序', '提示', {
							distinguishCancelAndClose: true,
							confirmButtonText: '去绑定',
							cancelButtonText: '关闭'
						}).then(() => {
							window.open(this.mallConfigInfo.WxAppAuthUrl)
						}).catch(action => {});
						return
					}
					window.localStorage.setItem('canEditSetinfos', true);
					window.localStorage.setItem('toPayResultFromPath', this.$route.path);

					this.setVisible = true;

				})
			},
			toSign() {
				this.$router.push({
					path: '/setting/setPayApply'
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.wx-pay {
		margin-left: 10px;
		line-height: 18px;

		.pay-info {
			display: flex;
			padding: 10px 15px;
			border: 1px solid #ddd;
			border-radius: 6px;
			max-width: 800px;
		}

		.icon {
			flex: 0 0 auto;
			margin-right: 10px;

			.icon-font {
				font-size: 60px;
				color: #44b449;
			}
		}

		.info {
			flex: 1 1 auto;
			overflow: hidden;

			.title {
				font-size: 20px;
				padding-top: 10px;
			}

			.tips {
				font-size: 14px;
				color: #999;
				margin-top: 10px;
			}

			.bind-info {
				font-size: 12px;
				color: #999;
				margin-bottom: 5px;
			}

			.config-info {
				position: relative;
				padding: 15px 15px 15px 15px;
				margin-top: 15px;
				width: 400px;
				font-size: 13px;
				background-color: #f5f7fa;
				border: 1px solid #ddd;

				.info-item {
					display: flex;
					margin-bottom: 10px;
					color: #000;

					.tit {
						flex: 0 0 auto;
						width: 100px;
						text-align: right;
						margin-right: 5px;
					}

					.cont {
						flex: 1 1 auto;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.info-icon {
						flex: 0 0 auto;
						width: 30px;
						margin-left: 20rpx;
						cursor: pointer;

						i {
							font-size: 16px;
						}
					}
				}
			}

			.edit-config {
				position: absolute;
				right: 20px;
				bottom: 10px;
			}
		}
	
		.bind-line{
			max-width: 660px;
			.i-have{
				padding: 5px 10px;
				border: 1px solid #ddd;
				font-size: 14px;
				display: flex;
				align-items: center;
			}
			.line{
				border: 1px solid #ddd;
				// padding: 15px 0 15px;
				margin-top: 20px;
				
				.line-title{
					height: 30px;
					line-height: 30px;
					padding-left: 15px;
					background-color: #dcdfe6;
					font-size: 14px;
					margin-bottom: 30px;
				}
					
			}
		}
	}

	.dialog-title {
		font-size: 15px;
		text-align: left;
		border-bottom: 1px solid #ddd;
		padding-bottom: 10px;
	}
	
	::v-deep .times-line{
		margin-left: 0;
	}
</style>
